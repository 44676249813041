<template>
  <div>
    <el-tooltip
      ref="contactCompany"
      :disabled="isFetchingDetails || (!contactRequested && hasRemainingCredits)"
      popper-class="is-light contact-company">
      <div
        v-if="contactRequested"
        slot="content">
        <div class="popover-tooltip-notification">
          <div class="flex-container">
            <div class="circle">
              <i class="checkmark-icon-default medium blue-color large-icon"/>
            </div>
            <p>You requested the contact info of this company. Please check your email for more details.</p>
          </div>
        </div>
      </div>
      <div
        v-if="!contactRequested && !hasRemainingCredits"
        slot="content">
        <div class="popover-tooltip-notification">
          <h3>Company Contact Request Limit Reached </h3>
          <div class="flex-container">
            <i class="database-upgrade-icon" />
            <p>You have reached your limit for Company Contact Request. Please
              <a
                href="#"
                class="inline-normal"
                @click="showUpgrade">upgrade</a>  your subscription plan to view more. Please call 855-573-9977 for further assistance.</p>
          </div>
        </div>
      </div>
      <button
        :class="{
          'btn-disable': !hasRemainingCredits || contactRequested || contacting
        }"
        class="btn-default btn-primary bordered"
        @click="contact(companyId)">
        {{ buttonContent }}
      </button>
    </el-tooltip>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { openUpgradeModal } from '~/mixins/upgradeModal/methods'

export default {
  name: 'ContactCompany',
  mixins: [openUpgradeModal],
  computed: {
    ...mapGetters('userCredits', ['contactRequestsCredits']),
    ...mapState('company', {
      companyId: state => state.companyId,
      contactRequested: state => state.contactRequested,
      contacting: state => state.contacting,
      isFetchingDetails: state => state.isFetchingDetails,
      hasRemainingCredits: function() {
        return this.contactRequestsCredits > 0
      }
    }),
    buttonContent() {
      return this.contactRequested
        ? 'Contact Info Requested'
        : 'Request Contact Info'
    }
  },
  methods: {
    ...mapActions('company', ['contactCompany']),
    showUpgrade() {
      this.$refs.contactCompany.$refs.popper.style.display = 'none'
      this.openUpgradeModal()
    },
    contact(companyId) {
      if (
        !this.contactRequested &&
        !this.contacting &&
        this.hasRemainingCredits
      ) {
        this.contactCompany(companyId)
      }
    }
  }
}
</script>
