<template>
  <multiselect
    v-model="selectedCountry"
    :options="availableCountries"
    :show-labels="false"
    :allow-empty="false"
    :searchable="false"
    placeholder="Select country here"
    label="name"
    track-by="name"
    class="custom-select"
    @open="$emit('toggle', true)"
    @close="$emit('toggle', false)">
    <template
      slot="singleLabel"
      slot-scope="props">
      <span class="multiselect-option">
        <img
          v-if="props.option.countryCode"
          :src="require(`~/assets/img/flag/${props.option.countryCode}.svg`)"
          :alt="props.option.name"
          class="flag">
        {{ formatter(props.option) }}
      </span>
    </template>
    <template
      slot="noResult">
      <span>No country available</span>
    </template>
    <template
      slot="option">
      <i class="fa fa-info-circle"/> <span class="coming-soon">Data for twelve Latin American countries, India, Russia, and Ukraine still in progress</span>
      <!-- @TODO - uncomment when ready -->
      <!-- <span class="multiselect-option">
        <img
          v-if="props.option.countryCode"
          :src="require(`~/assets/img/flag/${props.option.countryCode}.svg`)"
          :alt="props.option.name"
          class="flag">
        {{ formatter(props.option) }}
      </span> -->
    </template>
  </multiselect>
</template>

<script>
import { countries } from '~/config'

export default {
  name: 'CountriesDropdown',
  model: {
    prop: 'country',
    event: 'country'
  },
  props: {
    country: {
      // country code
      type: String,
      required: true
    },
    formatter: {
      type: Function,
      default(country) {
        return country.name
      }
    }
  },
  data() {
    return {
      availableCountries: countries
        // @TODO Fix when back-end is fixed
        .filter(country => country.countryCode === 'us')
        .map(country => {
          country.$isDisabled = true
          return country
        })
    }
  },
  computed: {
    selectedCountry: {
      get() {
        return this.availableCountries.find(c => c.countryCode === this.country)
      },
      set({ countryCode }) {
        this.$emit('country', countryCode)
      }
    }
  }
}
</script>
