<template>
  <el-breadcrumb separator-class="separator-icon">
    <el-breadcrumb-item :to="{ path: modifiedRootLink }">{{ rootName }}</el-breadcrumb-item>
    <span v-if="subPages.length">
      <el-breadcrumb-item
        v-for="(page, index) in subPages"
        :key="index"
        :class="{ 'normal-font': (index + 1 !== subPages.length) }"
        :to="page.link">
        {{ page.label }}
      </el-breadcrumb-item>
    </span>
    <span v-else>
      <el-breadcrumb-item
        :class="{ 'normal-font': subPage }"
        :to="currentLink">{{ currentPage }}</el-breadcrumb-item>
      <el-breadcrumb-item v-if="subPage">{{ subPage }}</el-breadcrumb-item>
    </span>
  </el-breadcrumb>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'Breadcrumb',
  props: {
    currentPage: {
      type: String,
      default: ''
    },
    currentLink: {
      type: String,
      default: ''
    },
    subPage: {
      type: String,
      default: ''
    },
    subPages: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    ...mapState(['rootLink', 'baseLink']),
    ...mapGetters(['currentSearchURL', 'shipmentAlertSearchURL']),
    modifiedRootLink() {
      switch (this.rootLink) {
        case this.shipmentAlertSearchURL:
          return this.baseLink
        default:
          return this.rootLink
      }
    },
    rootName() {
      switch (this.rootLink) {
        case this.currentSearchURL:
        case this.shipmentAlertSearchURL:
          return 'Search'
        case '/analytics':
          return 'Analytics'
        default:
          return 'Back'
      }
    }
  }
}
</script>
