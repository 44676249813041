<template>
  <section
    :class="{ 'custom-z-index': isCustomViewOpen }"
    class="section-content company-profile-content exp-1">
    <breadcrumb
      :sub-page="companyName"
      :current-link="module !== 'overview' ? 'overview' : ''"
      current-page="Company Profile"/>
    <div class="section-inner">
      <h1>{{ companyName }}</h1>
      <company-profile-toolbar :module="module">
        <nuxt-child />
      </company-profile-toolbar>
    </div>
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import Breadcrumb from '~/components/common/Breadcrumb'
import CompanyProfileToolbar from '~/components/company/CompanyProfileToolbar.vue'
import hideSidebarMixin from '~/mixins/hideSidebar'
import canvasTooltip from '~/utils/canvasTooltip'
import { validateTimezoneOffset } from '~/utils/validateStorage/helper-functions'

export default {
  name: 'CompanyId',
  scrollToTop: true,
  head() {
    return {
      title:
        this.getPageTitle() +
        ' - ' +
        ISNG_TITLE /*eslint-disable-line no-undef */
    }
  },
  components: {
    CompanyProfileToolbar,
    Breadcrumb
  },
  mixins: [hideSidebarMixin],
  async fetch({ store, route, error }) {
    const notFoundError = () => {
      if (location.href.indexOf('company') > -1)
        error({
          statusCode: 404,
          message: `Company with id: ${companyId} does not exist`
        })
    }

    const currentModule = route.path.split('/')[3].toLowerCase()
    const companyId = route.params.id
    const { state } = store
    const { getters } = store
    let dateRange = null

    //update user subscription date range in case it's stale
    await Promise.all([
      store.dispatch('fetchSettings', false, { root: true }),
      store.dispatch('userSubscriptions/getUserSubscriptions')
    ])

    const companyDateRange = getters['userSubscriptions/subscriptionDateRange']

    const searchDateRange = state.dateRange
    if (!store.state.isMounted) {
      // First load - user settings
      const { from, to } = companyDateRange
      dateRange = from && to ? companyDateRange : searchDateRange
    } else {
      // If previous path was NOT a company profile use the
      // default company dateRange value

      dateRange = companyDateRange
    }

    if (dateRange) {
      dateRange = {
        from: validateTimezoneOffset(dateRange.from),
        to: validateTimezoneOffset(dateRange.to)
      }
    }

    store.commit('company/setDateRange', dateRange)
    store.commit('company/setCompanyId', companyId)

    await store.dispatch('company/fetchTradersStatus')

    const promises = Promise.all([
      store.dispatch('company/initialize'),
      store.dispatch(`company/${currentModule}/initialize`, route)
    ])
      .then(() => {
        const { companyName } = state.company
        if (!companyName) {
          notFoundError()
        }
      })
      .catch(async err => {
        if (!state.company.companyName) {
          return notFoundError()
        }
        error()
        console.error(err)
      })

    // Only wait if initial page load
    if (!state.isMounted) {
      await promises
    }
  },
  computed: {
    ...mapState('company', {
      companyName: state => state.companyName,
      isImporter: state => state.isImporter,
      isSupplier: state => state.isSupplier,
      isCustomViewOpen: state => state.isCustomViewOpen
    }),
    thisYear() {
      return new Date().getFullYear()
    },
    module() {
      return this.$route.path.split('/')[3].toLowerCase()
    }
  },
  created() {
    const companyId = this.$route.params.id
    const chartTooltip = document.getElementById('canvas-tooltip')

    if (chartTooltip) {
      canvasTooltip.clear()
    }

    this.setCompanyId(companyId)
    this.prefetchModules(this.module)
    this.$store.dispatch('search/initialize')
    if (
      (this.module === 'importers' && !this.isSupplier) ||
      (this.module === 'suppliers' && !this.isImporter)
    ) {
      this.$router.push(`/company/${companyId}/overview`)
    }
  },
  destroyed() {
    // Don't clean up store if route changes to another company profile
    // fetch() occurs before route change, so destroyed is called after fetch
    if (
      this.$route.name.includes('company-id-overview') ||
      this.$route.name.includes('company-id-visualMap')
    ) {
      return
    }
    this.resetCompanyStates()
  },
  methods: {
    ...mapMutations('company', ['setCompanyId']),
    ...mapActions('company', ['resetCompanyStates', 'prefetchModules']),
    getPageTitle() {
      return this.$store.state.company.companyName || 'Company Details'
    }
  }
}
</script>
<style src="~/assets/scss/sections/company/company.scss" lang="scss"/>
<style src="~/assets/scss/sections/company/companies-table.scss" lang="scss"/>
